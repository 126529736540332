<template>
    <div class="bg-white p-4 rounded-md mt-2" style="padding:20px">
        <DataTable :value="cartas"
            class="p-datatable-sm text-xs"
            showGridlines
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            ref="tableList"
            removableSort
            v-model:expandedRows="expandedRows"
            @rowExpand="onRowExpand"
        >
            <template #empty>
            No hay datos para mostrar
            </template>
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column  field="id" header="N°. Carta" style="max-width:2rem" headerStyle="text-align: center;justify-content: center;" bodyStyle="text-align: center"/>
            <Column field="fecha_carta" header="Fecha Carta" headerStyle="text-align: center;justify-content: center;" style="min-width:0.5rem" bodyStyle="text-align: center"/>
            <Column field="fecha_recepcion" header="Fecha Recepción" headerStyle="text-align: center;justify-content: center;" style="min-width:5rem" bodyStyle="text-align: center"/>
            <Column field="laboratorio" header="Nombre Laboratorio" headerStyle="text-align: center;justify-content: center;" style="min-width:10rem" bodyStyle="text-align: center"/>
            <!-- <Column  field="createdUser" header="Usuario Creación" headerStyle="text-align: center;justify-content: center;" style="min-width:10rem" bodyStyle="text-align: center"/> -->
            <template #expansion="slotProps">
                <div class="orders-subtable p-2">
                    <DataTable :value="slotProps.data.detalle"
                        responsiveLayout="scroll"
                        class="p-datatable-sm text-xs"
                        currentPageReportTemplate="Mostrando {last} un total de {totalRecords}"
                        :rowsPerPageOptions="[5,10,20,50]"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        :paginator="true" :rows="5"
                    >
                        <Column field="codMx" header="Código Medicamento" headerStyle="text-align: center;justify-content: center;" style="min-width:0.5rem" bodyStyle="text-align: center"/>
                        <Column field="nombreMx" header="Descripción Medicamento" headerStyle="text-align: center;justify-content: center;" style="min-width:0.5rem" bodyStyle="text-align: center"/>
                        <Column field="fecha_mx_disponible" header="Fecha Mx Disponible" headerStyle="text-align: center;justify-content: center;" style="min-width:0.5rem" bodyStyle="text-align: center"/>
                        <Column field="estado" header="Estado" headerStyle="text-align: center;justify-content: center;" style="min-width:0.5rem" bodyStyle="text-align: center" />
                        <template #paginatorstart>
                            <Button type="button" icon="pi pi-refresh" class="p-button-sm" />
                        </template>
                        <template #paginatorend>
                            <Button type="button" icon="pi pi-cloud" class="p-button-sm" />
                        </template>
                    </DataTable>
                </div>
            </template>
            <Column field="" header="Acciones" style="max-width:3rem" bodyStyle="text-align: center">
                <template #body="{data}">
                    <div style="display: flex;justify-content: space-evenly;">
                        <Button icon="pi pi-download" v-tooltip.bottom="'Descargar Carta'" @click="downloadPdf(data.id)" class="p-button-success"/>
                        <Button icon="pi pi-trash" v-if="$can('pharmasan.compras.carta-agotado.eliminar') && !disableRemove" v-tooltip.bottom="'Eliminar Carta'" @click="deleteCarta(data.id)" class="p-button-danger"/>
                    </div>
                </template>
            </Column>
        </DataTable>
        <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[2,5,10,20,30]"
            :pageLinkSize="pageLinkSize"
            @page="onPage($event)"
        />
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import storeCartaAgotado from '../../store/store.cartaAgotado'
import Swal from 'sweetalert2'
export default {
  name: 'list-tabla',
    setup () {
        const pageLinkSize = ref(3)
        const expandedRows = ref([])
        const cartas = computed(() => storeCartaAgotado.getters.getCartas)
        const filters = computed(() => storeCartaAgotado.getters.getFilters)
        const disableRemove = computed(() => storeCartaAgotado.getters.getDisableRemove)
        const offset = filters.value.offset
        const page = filters.value.page
        const limit = filters.value.limit
        const totalRecords = computed(() => storeCartaAgotado.getters.getTotalRecords)
        const deleteCarta = (id) => {
            Swal.fire({
                icon: 'warning',
                title: '¿Esta seguro/a?',
                html:
                `¿Desea eliminar la carta con número: <b>${id}</b>?`,
                text: '¿Desea eliminar este registro?',
                showConfirmButton: true,
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    storeCartaAgotado.dispatch('deleteCarta', id).then((response) => {
                        storeCartaAgotado.dispatch('getLoad')
                        Swal.fire({
                            icon: 'success',
                            title: 'Éxito',
                            text: 'Carta de agotado eliminado correctamente',
                            showConfirmButton: true
                        })
                    })
                }
            })
        }
        const downloadPdf = (id) => {
            storeCartaAgotado.dispatch('downloadPdf', id)
        }
        const onRowExpand = (slotProps) => {
            storeCartaAgotado.dispatch('getDetails', slotProps.data.id).then(({ data }) => {
                const payload = {
                    index: cartas.value.findIndex((x) => x.id === slotProps.data.id),
                    detalle: data
                }
                storeCartaAgotado.commit('setCarta', payload)
            })
        }
        const collapseAll = () => {
            expandedRows.value = null
        }
        const onPage = ({ first, page, pageCount, rows }) => {
            filters.value.limit = rows
            filters.value.offset = page
            storeCartaAgotado.dispatch('getLoad')
            collapseAll()
        }
        return {
            cartas,
            deleteCarta,
            downloadPdf,
            onPage,
            offset,
            totalRecords,
            limit,
            page,
            pageLinkSize,
            expandedRows,
            disableRemove,
            onRowExpand,
            collapseAll
        }
    }
}
</script>
<style scoped>
::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
